@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap");

html,
body,
#root,
.app,
.content {
  height: 100%;
  width: 100%;
  font-family: "Source Sans Pro", sans-serif;
}

.app {
  display: flex;
  position: relative;
  width: 100%;

}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e0e0e0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}


/* Adicione estas regras no seu CSS */
.sidebar {
  position: fixed; /* Fixa o sidebar */
  top: 0;
  left: 0;
  height: 100vh; /* Altura total da tela */
  z-index: 1300; /* Garantir que o sidebar fique na frente */
  transition: width 0.3s; /* Transição suave para a largura */
}

.sidebar.collapsed {
  width: 80px; /* Largura do sidebar quando colapsado */
}

.sidebar.expanded {
  width: 250px; /* Largura do sidebar quando expandido */
}

.main-content {
  margin-left: 265px; /* Espaço à esquerda para o conteúdo principal */
  transition: margin-left 0.3s; /* Transição suave para a margem */
  width: "100%";
  flex-grow: 1; /* Permite que o conteúdo principal cresça para ocupar o espaço restante */

}

.sidebar.collapsed + .main-content {
  margin-left: 80px; /* Espaço à esquerda reduzido quando o sidebar está colapsado */
}




