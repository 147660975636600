/* Estilos para a impressão */
@media print {
    body {
      color: black !important;
    }
  
    .print-container {
      color: black !important;
      background-color: white !important;
    }
  }
  